import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import NewsHeader from "../../components/NewsHeader";
import SleepyNewsAnchor from "../../components/SleepyNewsAnchor";

const NewsPage = () => (
	<Layout>
		<Seo title="November 2024" />
		<NewsHeader />
		<h2>November 2024</h2>

		<h3 className="blogdate">
			Wednesday, November 27, 2024
		</h3>

		<h4 className="blogitemtitle">
			<SleepyNewsAnchor num="1">December livestream performance of Distant December</SleepyNewsAnchor>
		</h4>
		<div className="blogitembody">
			<p>
				<s>
					I haven't yet decided which platform I'll use (likely YouTube or UrsaLive), but let's save the date and time.
					Feel free to come back to this very post for updates as the showtime nears.
				</s>
				<br />
				<span className="bold">December 6 UPDATE</span>: The show will be at <a href="//ursalive.com/jesserivest">my Ursa Live page</a>.
				See after image/poster below for brief instructions on how to chat or be visible to me during the show.
			</p>

			<ul className="p">
				<li>
					<span className="bold">20:00 New York time on Wednesday, December 11, 2024</span>
					<ul>
						<li>
							add to your calendar via <a href="//www.timeanddate.com/worldclock/meetingdetails.html?year=2024&month=12&day=12&hour=1&min=0&sec=0&p1=256&p2=55&p3=265&p4=165&p5=45&p6=240&p7=22">this link</a>
						</li>
						<li>
							or verify time for your current location with <Link to="/time/?time=2024-12-11T22:00:00.000-03:00">this link</Link>
						</li>
					</ul>
				</li>
				<li>
					livestream platform: <a href="//ursalive.com/jesserivest">My Ursa Live page</a>
				</li>
			</ul>

			<p>
				The other week I listened on a nice pair of headphones to the entirety of my <span className="italic">Distant December</span> album,
				which I released last year while I was visiting and touring in Canada.
				It left me feeling warm and nostalgic 🥰.
				This year, I can't make it home for any shows or visiting, but I can put on a nice little show from my studio in Brasilia.
				And if you attend, we can even have a little visit!
				For an example of what it will look like,
				check out my <span className="bold">Time for a Song</span> video of <span className="italic">Aqui e Agora</span> over at my <Link to="/video/">video page</Link> (currently the top video).
			</p>
			<p>
				This performance won't remain viewable on the internet afterward; I hope you can make it to the show ❄️☃️🧤🧦🍷.
			</p>
			<p>
				(It's free to access and you don't need to reserve your place.)
			</p>

			<div className="main--centerwrapper p">
				<StaticImage
				src="../../images/posters/20241127 Distant December livestream - 1080 - lanczos non.jpg"
				alt="Jesse Rivest - Distant December - promo poster"
				placeholder="blurred"
				layout="constrained"
				className="main--centered"
				imgClassName="img--bordered-small"
				width={500}
				/>
			</div>

			<p>
				Added: instructions should you wish to chat or join the fan spotlight section at Ursa Live:
			</p>
			<ol>
				<li>
					Click login/signup; I assume you are signing up
				</li>
				<li>
					Choose Fan (not Artist)
				</li>
				<li>
					Create username, add email and password
				</li>
				<li>
					Submit; go to your email, find email and click to activate account
				</li>
				<li>
					You will then be logged in; soon you'll be asked two more prompts:
				</li>
				<li>
					Just CLOSE/SKIP: add profile photo
				</li>
				<li>
					Just CLOSE/SKIP: buy some livecoins for tipping/donating
				</li>
			</ol>

			<p>
				Done! Join me for the show!
			</p>

			<div className="main--centerwrapper p">
			</div>

		</div>

		<p>
		<Link to="/news/">Go back to the news index page</Link>
		</p>
	</Layout>
);

export default NewsPage;
